"use client";

import { Locale } from "@/i18n";
import React, { useEffect, useState } from "react";
import ImageAssets from "@/data/ImageAssets";
import Image from "next/image";
import { Link } from "@/navigation";
import { useTranslations } from "next-intl";
import HeaderLocaleSwitcher from "@/app-components/layout/header/locale-switcher";
import { usePathname } from "next/navigation";
import Headroom from "headroom.js";
import PhoneCta from "@/app-components/page/phone-cta";

const options = {
  tolerance: {
    up: 5,
    down: 10,
  },
};

type HeaderProps = {
  params: { locale: Locale };
  children: React.ReactNode;
};

// Pages with white backgrounds
const lightPages: string[] = [
  "//",
  "^pricing",
  "^gallery",
  "^blog$",
  "^contact",
  "^legal*",
];

function Header({ children, params: { locale } }: HeaderProps) {
  const t = useTranslations("header");
  const pathname = usePathname();

  // skip the language, returns `blog` or `pricing`
  const extractedPath = pathname.split("/").slice(2).join("/");


  const navbarTheme = lightPages.some((path) => extractedPath.match(path))
    ? "light"
    : "dark";

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu);

    const body = document.querySelector("body");
    if (!showMobileMenu) {
      body?.classList.add("navbar-active");
    } else {
      setTimeout(() => {
        body?.classList.remove("navbar-active");
      }, 0);
    }
  };

  useEffect(() => {
    showMobileMenu && toggleMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const element = document.querySelector("body");
    if (element) {
      const headroom = new Headroom(element, options);
      headroom.init();
    }
  }, []);

  return (
    <nav
      id="mainNav"
      className={`navbar navbar-expand-lg navbar-sticky navbar-${navbarTheme}`}
      data-nosnippet
    >
      <div className="container">
        <Link href="/" className="navbar-brand" locale={locale}>
          <Image
            src={
              navbarTheme === "dark"
                ? ImageAssets.LightLogo
                : ImageAssets.DarkLogo
            }
            alt="Voder studio Logo"
            width={35}
            height={35}
          />
        </Link>
        {/* Secondary */}
        <ul className={`navbar-nav navbar-nav-secondary order-lg-3`}>
          <li className="nav-item d-lg-none">
            <a href="#" className="nav-link nav-icon" onClick={toggleMenu}>
              <span className="bi bi-list"></span>
            </a>
          </li>

          <div className="nav-item d-none d-lg-block">
            <PhoneCta
              value="header"
              classname={`btn btn-outline-${
                navbarTheme === "light" ? "dark" : "white"
              } rounded-pill ms-2`}
            >
              <i className="bi me-1 bi-telephone-fill"></i>
              {t("reserve_appointment")}
            </PhoneCta>
          </div>
        </ul>

        {/* Primary */}
        <div
          className={`collapse navbar-collapse ${showMobileMenu && "show"}`}
          id="navbar"
          data-bs-parent="#mainNav"
        >
          <ul className="navbar-nav">
            {children}

            <HeaderLocaleSwitcher theme={navbarTheme} locale={locale} />
          </ul>
        </div>

        {/* Mobile User Menu */}
      </div>
    </nav>
  );
}

export default Header;
