"use client";

import { useState } from "react";
import ImageAssets from "@/data/ImageAssets";
import Image from "next/image";
import { useTranslations } from "next-intl";
import { usePathname, useRouter } from "@/navigation";
import {Locale} from "@/i18n";

type HeaderLocaleSwitcherProps = {
  theme: string;
  locale: Locale;
};

export default function HeaderLocaleSwitcher({theme, locale}: HeaderLocaleSwitcherProps) {
  const t = useTranslations("header");

  const [showFlagMenu, setShowFlagMenu] = useState(false);
  const toggleFlag = () => {
    setShowFlagMenu(!showFlagMenu);
  };

  const pathName = usePathname();
  const router = useRouter();

  const dropDownMenuBGStyle = theme === 'light'? "" : 'bg-light';

  const LANGUAGES = [
    {
      locale: "en",
      img: ImageAssets.UKFlag,
      label: t("languages.en"),
    },
    {
      locale: "bg",
      img: ImageAssets.BGFlag,
      label: t("languages.bg"),
    },
  ];

  const INDEX_LANG = LANGUAGES.findIndex(language => language.locale == locale);
  const LANGUAGE_LABEL = LANGUAGES[INDEX_LANG].label;


  /*LANGUAGE_LABEL = t("nav.language")*/

  return (
    <li className="dropdown nav-item">
      <a
        className="nav-link small pb-2 dropdown-toggle"
        onClick={toggleFlag}
        href="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="bi bi-globe fa-fw me-1"></i>
        {
          LANGUAGE_LABEL
        }
      </a>

      <ul
        className={`dropdown-menu dropdown-animation ${dropDownMenuBGStyle} dropdown-menu-end min-w-auto ${
          showFlagMenu && "show"
        }`}
        aria-labelledby="languageDropdown"
      >
        {LANGUAGES.map((item) => (
          <li key={item.locale}>
            <a
              href="#"
              onClick={() => {
                router.replace(pathName, { locale: item.locale });
              }}
              className="dropdown-item me-4 pointer"
            >
              <Image
                className="fa-fw me-2"
                src={item.img}
                height="13"
                width="30"
                alt="language flag"
              />
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </li>
  );
}
