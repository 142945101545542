const S3_URL = "https://assets.voderstudio.com";

const ImageAssets = {
  // Fav icons
  FavIconIco: `${S3_URL}/assets/favicon/favicon.ico`,
  FavIconSVG: `${S3_URL}/assets/favicon/logo.svg`,
  FavIconApple: `${S3_URL}/assets/favicon/apple-touch-icon.png`,

  // Social
  SocialLogo: `${S3_URL}/assets/logo/voder logo meta image x1000.jpg`,
  // Logos
  LightLogo: `${S3_URL}/assets/logo/logo-light.svg`,
  DarkLogo: `${S3_URL}/assets/logo/logo-dark.svg`,

  // Language flags
  BGFlag: `${S3_URL}/assets/images/layout/language-flags/bg.svg`,
  UKFlag: `${S3_URL}/assets/images/layout/language-flags/uk.svg`,

  // SVG bkg files:
  NotFoundImg: `${S3_URL}/assets/images/layout/svg/404.svg`,
  BkgSVG1: `${S3_URL}/assets/images/layout/svg/pattern-lg-light.svg`,

  HomeBanner: `${S3_URL}/assets/images/home/voder-studio-hero.jpg`,

  MassagesLymphaticDrainageHero: `${S3_URL}/assets/images/lymphatic-drainage/lymphatic-drainage-message-hero.jpg`,
  MassagesOsteopathyHero: `${S3_URL}/assets/images/osteopathy/osteopathy-message-hero.jpg`,
  MassagesMyofascialHero: `${S3_URL}/assets/images/myofascial/myofascial-message-hero.jpg`,

  // Home
  MassageOils: `${S3_URL}/assets/images/home/massage-oils.jpg`,
  Map: `${S3_URL}/assets/images/home/map.jpg`,
  PatternLayout: `${S3_URL}/assets/images/layout/svg/pattern-lg-light.svg`,

  GalleryImage1: `${S3_URL}/assets/images/gallery/out/1.jpg`,
  GalleryImage2: `${S3_URL}/assets/images/gallery/out/2.jpg`,
  GalleryImage3: `${S3_URL}/assets/images/gallery/out/3.jpg`,
  GalleryImage4: `${S3_URL}/assets/images/gallery/out/4.jpg`,
  GalleryImage5: `${S3_URL}/assets/images/gallery/out/5.jpg`,
  GalleryImage6: `${S3_URL}/assets/images/gallery/out/6.jpg`,
  GalleryImage7: `${S3_URL}/assets/images/gallery/out/7.jpg`,
  GalleryImage8: `${S3_URL}/assets/images/gallery/out/8.jpg`,
  GalleryImage9: `${S3_URL}/assets/images/gallery/out/9.jpg`,
  GalleryImage10: `${S3_URL}/assets/images/gallery/out/10.jpg`,
  GalleryImage11: `${S3_URL}/assets/images/gallery/out/11.jpg`,
  GalleryImage12: `${S3_URL}/assets/images/gallery/out/12.jpg`,
  GalleryImage13: `${S3_URL}/assets/images/gallery/out/13.jpg`,
  GalleryImage14: `${S3_URL}/assets/images/gallery/out/14.jpg`,
  GalleryImage15: `${S3_URL}/assets/images/gallery/out/15.jpg`,
  GalleryImage16: `${S3_URL}/assets/images/gallery/out/16.jpg`,
  GalleryImage17: `${S3_URL}/assets/images/gallery/out/17.jpg`,
  GalleryImage18: `${S3_URL}/assets/images/gallery/out/18.jpg`,
  GalleryImage19: `${S3_URL}/assets/images/gallery/out/19.jpg`,
  GalleryImage20: `${S3_URL}/assets/images/gallery/out/20.jpg`,
  GalleryImage21: `${S3_URL}/assets/images/gallery/out/21.jpg`,
  GalleryImage22: `${S3_URL}/assets/images/gallery/out/22.jpg`,
  GalleryImage23: `${S3_URL}/assets/images/gallery/out/23.jpg`,
  GalleryImage24: `${S3_URL}/assets/images/gallery/out/24.jpg`,
  GalleryImage25: `${S3_URL}/assets/images/gallery/out/25.jpg`,
  GalleryImage26: `${S3_URL}/assets/images/gallery/out/26.jpg`,
  GalleryImage27: `${S3_URL}/assets/images/gallery/out/27.jpg`,
  GalleryImage28: `${S3_URL}/assets/images/gallery/out/28.jpg`,
  GalleryImage29: `${S3_URL}/assets/images/gallery/out/29.jpg`,
  GalleryImage30: `${S3_URL}/assets/images/gallery/out/30.jpg`,
  GalleryImage31: `${S3_URL}/assets/images/gallery/out/31.jpg`,
  GalleryImage32: `${S3_URL}/assets/images/gallery/out/32.jpg`,
  GalleryImage33: `${S3_URL}/assets/images/gallery/out/33.jpg`,
  GalleryImage34: `${S3_URL}/assets/images/gallery/out/34.jpg`,
  GalleryImage35: `${S3_URL}/assets/images/gallery/out/35.jpg`,
  GalleryImage36: `${S3_URL}/assets/images/gallery/out/36.jpg`,


  GiftCard: `${S3_URL}/assets/images/pricing/gift-card.svg`,


  LymphaticDrainageMassage1: `${S3_URL}/assets/images/gallery/out/4.jpg`,
  LymphaticDrainageMassage2: `${S3_URL}/assets/images/gallery/out/7.jpg`,
  LymphaticDrainageMassage3: `${S3_URL}/assets/images/gallery/out/19.jpg`,
  LymphaticDrainageMassage4: `${S3_URL}/assets/images/gallery/out/1.jpg`,
  LymphaticDrainageMassage5: `${S3_URL}/assets/images/gallery/out/5.jpg`,
  LymphaticDrainageMassage6: `${S3_URL}/assets/images/gallery/out/23.jpg`,
  LymphaticDrainageMassage7: `${S3_URL}/assets/images/gallery/out/2.jpg`,
  LymphaticDrainageMassage8: `${S3_URL}/assets/images/gallery/out/36.jpg`,
  LymphaticDrainageMassage9: `${S3_URL}/assets/images/gallery/out/12.jpg`,

  Osteopathy1: `${S3_URL}/assets/images/gallery/out/4.jpg`,
  Osteopathy2: `${S3_URL}/assets/images/gallery/out/7.jpg`,
  Osteopathy3: `${S3_URL}/assets/images/gallery/out/19.jpg`,
  Osteopathy4: `${S3_URL}/assets/images/gallery/out/1.jpg`,
  Osteopathy5: `${S3_URL}/assets/images/gallery/out/5.jpg`,
  Osteopathy6: `${S3_URL}/assets/images/gallery/out/23.jpg`,
  Osteopathy7: `${S3_URL}/assets/images/gallery/out/2.jpg`,
  Osteopathy8: `${S3_URL}/assets/images/gallery/out/36.jpg`,
  Osteopathy9: `${S3_URL}/assets/images/gallery/out/12.jpg`,

};

export default ImageAssets;
